// src/DashboardScreen/components/UserGreeting.js
import React from 'react';

const UserGreeting = ({ patientName }) => {
  return (
    <header className="header-section">
      <div className="user-greeting">
        <img src="/assets/images/patient.png" alt="User avatar" className="user-avatar" />
        <p className="greeting-text">Hi { patientName }, Welcome back.</p>
      </div>
      <div className="action-button">
        <nav className="button-wrapper">
          <button className="nav-button" aria-label="Navigation menu">
            <img src="/assets/images/notification.svg" alt="" className="nav-icon" />
          </button>
        </nav>
      </div>
    </header>
  );
};

export default UserGreeting;
