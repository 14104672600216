// src/AnonymousScreen/LandingScreen.js
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import ButtonContainer from './components/ButtonContainer';
import './assets/css/style.css';

const LandingScreen = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const urlPath = window.location.pathname;
    const id = urlPath.split('/').pop(); // Get the last segment of the URL

    const apiUrl = `http://ec2-54-251-181-104.ap-southeast-1.compute.amazonaws.com:5500/pwa/clinic/land/${id}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => setData(data))
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again later.');
      });
  }, []);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!data) {
    return (
      <div className="loading-container">
        <div className="loading-text">Loading</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
      </div>
    );
  }

  console.log(data);

  const id = window.location.pathname.split('/').pop(); // Extract id from URL path
  const logoUrl = data?.SERVER_RESPONSE?.clinicData?.logoUrl; // Get logoUrl from API data

  return (
    <section className="container">
      <header className="header">
        <Header 
          emergencyMessage={data?.SERVER_RESPONSE?.emergencyMessage} 
          logoUrl={logoUrl}  // Pass logoUrl to Header
        />
      </header>
      <main className="main-content">
        <MainContent 
          heading={data?.SERVER_RESPONSE?.clinicData?.name} 
          moto={data?.SERVER_RESPONSE?.clinicData?.description} 
          services={data?.SERVER_RESPONSE?.landingForm?.form} 
          clinicId={id}
          logoUrl={logoUrl}
        />
      </main>
      <ButtonContainer id={id} logoUrl={logoUrl} /> {/* Pass id and logoUrl to ButtonContainer */}
    </section>
  );
};

export default LandingScreen;
