// src/PatientScreen/DashboardScreen.js
import React from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import NavigationMenu from './components/NavigationMenu';
import UserGreeting from './components/UserGreeting';
import AppointmentCard from './components/AppointmentCard';
import './assets/css/style.css';

import { useLocation } from 'react-router-dom';

const DashboardScreen = () => {

  const location = useLocation();
  const dashboardData = location.state?.dashboardData;
  const logoUrl = dashboardData?.SERVER_RESPONSE?.clinicData?.logoUrl; // Get logoUrl from API data
  const userNextAppointment = dashboardData?.SERVER_RESPONSE?.userSession?.userNextAppointment; // Get userNextAppointment

  console.log(dashboardData);

  return (
    <section className="container">
      <Header 
          emergencyMessage={dashboardData?.SERVER_RESPONSE?.emergencyMessage} 
          logoUrl={logoUrl}  // Pass logoUrl to Header
      />

      <section className="appointment-container">
        <UserGreeting patientName={dashboardData.SERVER_RESPONSE.userSession.userName} />
        <AppointmentCard nextAppointment={userNextAppointment} /> {/* Pass the appointment data */}
      </section>

      <main className="main-content">
        <MainContent 
          heading={dashboardData?.SERVER_RESPONSE?.clinicData?.name} 
          moto={dashboardData?.SERVER_RESPONSE?.clinicData?.description} 
          services={dashboardData?.SERVER_RESPONSE?.landingForm?.form} 
        />
      </main>

      <NavigationMenu clinicId={dashboardData?.SERVER_RESPONSE?.clinicData?.clinicId} />
    </section>
  );
};

export default DashboardScreen;
