import React, { useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './App.css';
import './transitions.css'; // CSS for transition animations
import LandingScreen from './AnonymousScreen/LandingScreen';
import LoginPage from './AnonymousScreen/components/LoginPage';
import DashboardScreen from './PatientScreen/DashboardScreen';
import ServiceDetailPage from './AnonymousScreen/components/ServiceDetailPage';

function App() {
  const location = useLocation();
  const nodeRef = useRef(null);

  return (
    <div className="App">
      <TransitionGroup>
        <CSSTransition
          nodeRef={nodeRef}
          key={location.pathname}
          classNames="fade"
          timeout={300}
        >
          <div ref={nodeRef}>
            <Routes location={location}>
              {/* Route with dynamic ID */}
              <Route path="/" element={<LandingScreen />} />
              <Route path="/:id" element={<LandingScreen />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/dashboard" element={<DashboardScreen />} />
              <Route path="/service/:serviceId/:clinicId" element={<ServiceDetailPage />} />
            </Routes>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default App;
