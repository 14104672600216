import React from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

const MainContent = ({ heading, moto, services, clinicId, logoUrl }) => {
  // const [altTextValue, setAltTextValue] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Initialize Survey model
  const survey = new Model(services);

  // Event to handle after each question is rendered
  survey.onAfterRenderQuestion.add((sender, options) => {
    const questionElement = options.htmlElement;
    const question = options.question;
    const parentPanel = question.parent;

    // Find the image question with altText in the same panel as the expression
    const imageQuestion = parentPanel?.elements.find(
      (elem) => elem.getType() === "image" && elem.altText
    );

    // Click handler to handle element clicks and navigate
    const handleClick = () => {
      if (imageQuestion) {
        const altText = imageQuestion.altText;
        const serviceId = altText.replace('sid:', ''); // Trim the "sid:" part
    
        // Navigate to /service/:serviceId using the trimmed altText value and pass logoUrl in the state
        navigate(`/service/${serviceId}/${clinicId}`, {
          state: { logoUrl: logoUrl } // Pass logoUrl along with the state
        });
      }
    };
    

    // Add click event to the image element
    if (question.getType() === "image") {
      questionElement.addEventListener("click", handleClick);
    }

    // Add click event to the title of the expression element (capturing the h5 tag)
    if (question.getType() === "expression") {
      const titleElement = questionElement.querySelector(".sd-title");
      if (titleElement) {
        titleElement.style.cursor = "pointer"; // Change cursor to pointer to indicate clickability
        titleElement.addEventListener("click", handleClick);
      }
    }
  });

  // Survey completion event (optional)
  survey.onComplete.add((sender) => {
    console.log("Survey data:", JSON.stringify(sender.data, null, 3));
  });

  return (
    <main className="main-content">
      <h1 className="welcome-text">{heading}</h1>
      <p className="subtitle">{moto}</p>
      
      {/* Render SurveyJS form */}
      <Survey model={survey} />
    </main>
  );
};

export default MainContent;
