import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';  // Import useLocation to get the state
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import Header from './Header';
import ButtonContainer from './ButtonContainer';
import 'survey-core/defaultV2.min.css';

const ServiceDetailPage = () => {
  const { serviceId, clinicId } = useParams();
  const { state } = useLocation(); // Get the state passed through navigate
  const [formData, setFormData] = useState(null);
  const [error, setError] = useState(null);

  // Retrieve logoUrl from state passed through navigate
  const logoUrl = state?.logoUrl;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `http://ec2-54-251-181-104.ap-southeast-1.compute.amazonaws.com:5500/pwa/clinicserviceform?clinicId=${clinicId}&formId=${serviceId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setFormData(data.SERVER_RESPONSE.resource.form); // Get form JSON
      } catch (err) {
        console.error('Error fetching service details:', err);
        setError('Failed to fetch service details. Please try again later.');
      }
    };

    fetchData();
  }, [serviceId, clinicId]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!formData) {
    return (
      <div className="loading-container">
        <div className="loading-text">Loading</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
      </div>
    );
  }

  // Initialize SurveyJS Model
  const survey = new Model(formData);

  return (
    <section className="container">
      {/* Header Section with Logo */}
      <header className="header">
        <Header logoUrl={logoUrl} />  {/* Pass logoUrl to Header */}
      </header>

      {/* SurveyJS Form */}
      <main className="main-content">
        <Survey model={survey} />
      </main>

      {/* Footer Buttons */}
      <footer>
        <ButtonContainer id={clinicId} logoUrl={logoUrl} />
      </footer>
    </section>
  );
};

export default ServiceDetailPage;
