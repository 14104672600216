// src/PatientScreen/components/AppointmentCard.js
import React from 'react';

const AppointmentCard = ({ nextAppointment }) => {

  // console.log(nextAppointment);

  // If nextAppointment is an array, loop through it
  if (Array.isArray(nextAppointment)) {
    return (
      <article className="appointment-card">
        {nextAppointment.map((appointment, index) => (
          <div key={index} className="appointment-info">
            <div className="date-info">
              <h2 className="appointment-label">Next Appointment</h2>
              <p className="appointment-date">{appointment.appDate}</p>
            </div>
            <div className="time-info">
              <div className="time-wrapper">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/7ef94a35a3c546c9a32b508134a22efc/0b0913e04c817a0fc98407a2819f4f8f14ab5d1acbcb3b9f336bf289d7c3d4ed?apiKey=7ef94a35a3c546c9a32b508134a22efc&"
                  alt=""
                  className="time-icon"
                />
                <p className="appointment-time">{appointment.appStartTime} - {appointment.appEndTime}</p>
              </div>
              <p className="appointment-type">{appointment.location}</p>
            </div>
          </div>
        ))}
      </article>
    );
  }

  // If nextAppointment is an object, render it directly
  if (nextAppointment && typeof nextAppointment === 'object') {
    return (
      <article className="appointment-card">
        <div className="appointment-info">
          <div className="date-info">
            <h2 className="appointment-label">Next Appointment</h2>
            <p className="appointment-date">{nextAppointment.appDate}</p>
          </div>
          <div className="time-info">
            <div className="time-wrapper">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/7ef94a35a3c546c9a32b508134a22efc/0b0913e04c817a0fc98407a2819f4f8f14ab5d1acbcb3b9f336bf289d7c3d4ed?apiKey=7ef94a35a3c546c9a32b508134a22efc&"
                alt=""
                className="time-icon"
              />
              <p className="appointment-time">{nextAppointment.appStartTime} - {nextAppointment.appEndTime}</p>
            </div>
            <p className="appointment-type">{nextAppointment.location}</p>
          </div>
        </div>
      </article>
    );
  }

  return null; // If no data available, return nothing
};

export default AppointmentCard;
