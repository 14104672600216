// src/AnonymousScreen/components/LoginPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation for accessing the passed state
import Header from './Header';

const LoginPage = () => {
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const { id, logoUrl } = location.state || {};  // Access the id and logoUrl passed via state

  useEffect(() => {
    if (!id || !logoUrl) {
      console.error('ID or Logo URL is missing!');
    }
  }, [id, logoUrl]);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setErrorMessage('Please enter both email and password');
      return;
    }

    try {
      const response = await fetch('http://ec2-54-251-181-104.ap-southeast-1.compute.amazonaws.com:5500/pwa/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password, clinicId: id }) // Pass the id in the request body
      });

      const data = await response.json();

      if (data.AUTH) {
        navigate('/dashboard', { state: { dashboardData: data } });
      } else {
        setErrorMessage('Invalid Email or Password');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  return (
    <section className="container">
      <Header showEmergencyBanner={false} logoUrl={logoUrl} /> {/* Pass logoUrl to Header */}
      <main className="main-content">
        <section className="password-input-container">
          <div className="password-field">
            <label htmlFor="email" className="password-label">Email</label>
            <div className="password-input-wrapper">
              <input
                type="text"
                id="email"
                className="password-input"
                placeholder=""
                aria-label="Enter your email"
                value={username}
                onChange={handleEmailChange}
              />
            </div>
          </div>

          <div className="password-field">
            <label htmlFor="password" className="password-label">Password</label>
            <div className="password-input-wrapper">
              <input
                type="password"
                id="password"
                className="password-input"
                placeholder=""
                aria-label="Enter your password"
                value={password}
                onChange={handlePasswordChange}
              />
              <button className="password-toggle" aria-label="Toggle password visibility">
                <img src="https://cdn.builder.io/api/v1/image/assets/7ef94a35a3c546c9a32b508134a22efc/36f9fb0c3224292095e361c2564c58fd09a2f8a513229a48bbfb1ffa6eed7c09?apiKey=7ef94a35a3c546c9a32b508134a22efc&" alt="Toggle visibility" width="16" height="16" />
              </button>
            </div>
          </div>

          {errorMessage && (
            <div className="error-message">{errorMessage}</div>
          )}

          <div className="actions-wrapper">
            <button type="submit" className="submit-button" onClick={handleSubmit}>Continue</button>
            <a href="/forgot-password" className="forgot-password">Forgot password?</a>
            <a href="/back" className="back-link">
              <img src="https://cdn.builder.io/api/v1/image/assets/7ef94a35a3c546c9a32b508134a22efc/8b12abae0b8d41a1f5de0fd0e06a26fd41d6dd5786b17ef0d90b4c872e8ee950?apiKey=7ef94a35a3c546c9a32b508134a22efc&" alt="Back" className="back-icon" aria-hidden="true" />
              <span>Go Back to the Sign Up</span>
            </a>
          </div>
        </section>
      </main>
    </section>
  );
};

export default LoginPage;
