// src/LandingScreen/components/MainContent.js
import React from 'react';
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

const MainContent = ({ heading, moto, services }) => {
  
  // parse surveyJS JSON to DOM
  const survey = new Model(services);
  survey.onComplete.add((sender, options) => {
    console.log(JSON.stringify(sender.data, null, 3));
  });

  return (
    <main className="main-content">
      <h1 className="welcome-text">{heading}</h1>
      <p className="subtitle">{moto}</p>
      <Survey model={survey} />
    </main>
  );
};

export default MainContent;