// src/AnonymousScreen/components/Header.js
import React from 'react';
import Logo from './Logo';
import EmergencyBanner from './EmergencyBanner';

const Header = ({ emergencyMessage, logoUrl, showEmergencyBanner = true }) => {
  return (
    <header className="header">
      <Logo logoUrl={logoUrl} />
      {showEmergencyBanner && emergencyMessage && <EmergencyBanner emergencyMessage={emergencyMessage} />}
    </header>
  );
};

export default Header;
