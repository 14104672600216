import React from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate instead of useHistory

const NavigationMenu = ({ clinicId }) => {
  const navigate = useNavigate(); // Get the navigate function for navigation

  // Logout function to handle the API call and redirect
  const handleLogout = async () => {
    try {
      const response = await fetch('http://ec2-54-251-181-104.ap-southeast-1.compute.amazonaws.com:5500/pwa/logout', {
        method: 'GET', // Changed to GET request
        headers: {
          'Content-Type': 'application/json',
        },
        // No body data is typically sent with a GET request, so we omit that
      });

      if (!response.ok) {
        throw new Error('Logout failed');
      }

      // On success, navigate to the landing page
      const landingPageUrl = `/${clinicId}`;  // Modify based on your needs
      navigate(landingPageUrl); // Use navigate for redirection
    } catch (error) {
      console.error('Error during logout:', error);
      // Optionally, handle error by showing a message or fallback
    }
  };

  return (
    <nav className="navigation-menu">
      <ul className="menu-items">
        <li className="menu-item menu-item-home">
          <img src="https://cdn.builder.io/api/v1/image/assets/7ef94a35a3c546c9a32b508134a22efc/b3754c52b0a9f48bcf0ca5685d4c4128580b0e1061516dc09fe13c259632d471?apiKey=7ef94a35a3c546c9a32b508134a22efc&" alt="" className="menu-icon" />
          <span className="menu-text">Home</span>
        </li>
        <li className="menu-item menu-item-health">
          <img src="https://cdn.builder.io/api/v1/image/assets/7ef94a35a3c546c9a32b508134a22efc/6a3e45e0195532b857db2909162d0846cc680e8acf25f76fa2ddad793b1f65c9?apiKey=7ef94a35a3c546c9a32b508134a22efc&" alt="" className="menu-icon" />
          <span className="menu-text">Health</span>
        </li>
        <li className="menu-item menu-item-care">
          <div className="care-icon-wrapper">
            <img src="https://cdn.builder.io/api/v1/image/assets/7ef94a35a3c546c9a32b508134a22efc/a011e0c0e4fd9e2d32e57c863403100c33905aa421828f8975b817a83011e432?apiKey=7ef94a35a3c546c9a32b508134a22efc&" alt="" className="care-icon" />
          </div>
          <span className="care-text">Get Care</span>
        </li>
        <li className="menu-item menu-item-visits">
          <img src="https://cdn.builder.io/api/v1/image/assets/7ef94a35a3c546c9a32b508134a22efc/73c8bc9a7416b150918fe5507e8afe7540ff138fed54910a6cab7457745fae83?apiKey=7ef94a35a3c546c9a32b508134a22efc&" alt="" className="menu-icon" />
          <span className="menu-text">Visits</span>
        </li>
        <li className="menu-item menu-item-settings" onClick={handleLogout}>
          <img src="https://cdn.builder.io/api/v1/image/assets/7ef94a35a3c546c9a32b508134a22efc/4cf5e06d9499e5f140cb4cad36cde2dd338da6a1260620b8f0217b309aeffa08?apiKey=7ef94a35a3c546c9a32b508134a22efc&" alt="" className="menu-icon" />
          <span className="menu-text">Logout</span>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationMenu;
