// src/AnonymousScreen/components/Logo.js
import React from 'react';

const Logo = ({logoUrl}) => {
  return (
    <div className="logo-section">
      <div className="logo-wrapper">
        <div className="logo">
          <img
            src={logoUrl}
            alt="Company Logo"
            className="logo-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Logo;
