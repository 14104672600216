// src/AnonymousScreen/components/ButtonContainer.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ButtonContainer = ({ id, logoUrl }) => {  // Accept logoUrl as a prop
  const navigate = useNavigate();

  const handleLoginClick = () => {
    // Corrected: Navigate to `/login` and pass both id and logoUrl as state
    navigate(`/login`, { state: { id, logoUrl } });
  };

  return (
    <div className="button-container">
      <button className="btn btn-register">Register</button>
      <button className="btn btn-login" onClick={handleLoginClick}>Login</button>
    </div>
  );
};

export default ButtonContainer;
